import React from 'react';
import styled from 'styled-components';
import 'rc-slider/assets/index.css';
import FirstViewport from '@/components/FirstViewport';
import {
  Body2,
  Description,
  H1,
  H2,
  H3,
  StrongInitial,
} from '@/components/Typography';
import Vp1Image from '../assets/img/recurringVp1.png';
import TickedItems from '@/components/TickedItems';
import ButtonDefault from '@/components/ButtonDefault';
import Section from '@/components/Section';
import { RecurringPaymentsPageContent } from '@/pages/recurring-payments';
import CarouselCard from '@/components/CarouselCard';
import UseCaseCardBig from '@/components/UseCaseCardBig';
import HowItWorksCard from '@/components/HowItWorksCard';
import CbdPluginCard from '@/components/CbdPluginCard';
import Vp6Image from '../assets/img/cbdVp8Bg.png';
import bannerBg from '../assets/img/bannerFenaTerminal.png';
import BottomMessageType2 from '@/components/BottomMessageType2';

const FirstViewportWrapper = styled.div`
  background: linear-gradient(123.46deg, #fbfbfd 45.14%, #e7edf3 100.49%);
`;

const StyledStrong = styled(StrongInitial)`
  margin-top: 2rem;
`;

const DescriptionWrapper = styled.div`
  margin: 2rem 0;
`;

const SecondViewport = styled(Section)``;

const Label = styled(H3)`
  text-align: center;
  @media (max-width: 1200px) {
    text-align: center;
  }
`;

const CardWrapper = styled.div`
  display: flex;
  margin-top: 3rem;
  flex-wrap: wrap;
  margin-bottom: 3rem;
  @media (max-width: 1200px) {
    flex-basis: 100%;
    flex-wrap: wrap;
  }
`;

const ViewportsWrapper = styled.div`
  background-color: #fff;
`;

const ThirdViewport = styled(Section)`
  padding-top: 0;
  padding-bottom: 0;
`;

const FourthViewport = styled(Section)`
  padding-bottom: 0;
`;

const FifthViewport = styled(Section)`
  padding-top: 2rem;
`;

const SixthViewport = styled(Section)`
  padding: 5rem calc((100vw - 1500px) / 2);
  padding-bottom: 10rem;
  background-color: #fff;
  background-image: ${(props) => `url(${props.resource})`};
  background-repeat: no-repeat;
  background-size: cover;
  @media (max-width: 1200px) {
    padding: 5rem 2rem 1rem 2rem;
  }
`;

const SeventhViewport = styled(Section)`
  background-color: #fff;
`;

const HowItWorksCardWrap = styled.div`
  display: flex;
  margin-top: 1rem;
  flex-wrap: nowrap;
  @media (max-width: 1200px) {
    flex-wrap: wrap;
  }
`;

const StyledDescription = styled(Description)`
  text-align: center;
  &:first-of-type {
    margin-top: 2rem;
  }
`;

// const StyledDescriptionVp7 = styled(StyledDescription)`
//   font-weight: 500;
//   margin-top: 1rem;
// `;

const StyledH2 = styled(H2)`
  text-align: center;
`;

const PluginBody2 = styled(Body2)`
  margin-top: 1.5rem;
  text-align: center;
  margin-top: 2rem;
  :nth-of-type(2n) {
    margin-top: 5rem;
  }
  :nth-of-type(3n) {
    margin-top: 9rem;
  }
  @media (max-width: 1200px) {
    :nth-of-type(2n) {
      margin-top: 3rem;
    }
    :nth-of-type(3n) {
      margin-top: 3rem;
    }
  }
`;

const PluginCardWrapper = styled.div`
  display: flex;
  margin-top: 1.5rem;
  gap: 1rem;
  flex-wrap: wrap;
  justify-content: space-evenly;
  :nth-of-type(2n) {
    justify-content: center;
    gap: 2rem;
  }
  :nth-of-type(3n) {
    justify-content: center;
    gap: 2rem;
  }
  @media (max-width: 1200px) {
    flex-wrap: wrap;
  }
`;

// const SliderWrapper = styled.div`
//   padding: 0 10rem;
//   @media (max-width: 1200px) {
//     padding: 0;
//   }
// `;

// const PriceWrapper = styled.div`
//   display: flex;
//   justify-content: center;
//   margin-top: 6rem;
//   margin-bottom: 10rem;
// `;

// const PriceContainer = styled.div`
//   display: flex;
//   background: linear-gradient(81.7deg, #e7edf3 8.96%, #e4e6f0 104.78%);
//   border-radius: 15px;
//   border: 1px solid #dbe3eb;
//   padding: 1.5rem;
//   flex-basis: 20%;
// `;

// const PriceLeft = styled.div`
//   display: flex;
//   align-items: center;
//   border-right: 1px solid #c2cedb;
//   padding-right: 0.5rem;
//   flex-basis: 20%;
// `;

// const PriceRight = styled.div`
//   display: flex;
//   justify-content: center;
//   flex-basis: 80%;
//   padding-left: 1.5rem;
//   @media (max-width: 1200px) {
//     padding-left: 1rem;
//   }
// `;

// const Price = styled.p`
//   font-size: 30px;
//   font-weight: 500;
// `;

// const StyledSlider = styled(Slider)`
//   .rc-slider-handle-dragging {
//     box-shadow: 0 0 0 15px #2cd19d1d !important;
//   }
//   .rc-slider-dot-active {
//     border: none;
//     background-color: #dbe3eb;
//   }
//   margin-top: 0.5rem;
// `;

// const SliderMarkBody4 = styled(Body4)`
//   margin-top: 1.5rem;
//   @media (max-width: 1200px) {
//     font-size: 80%;
//   }
// `;

// const SliderMarkBody4Red = styled(SliderMarkBody4)`
//   color: #ef6355;
// `;

interface RecurringPaymentsProps {
  content: RecurringPaymentsPageContent;
}

const RecurringPaymentsContainer: React.FunctionComponent<
  RecurringPaymentsProps
> = ({
  content: {
    viewport1,
    viewport2,
    viewport3,
    viewport4,
    viewport5,
    viewport6,
    viewport7,
  },
}) => (
  // const trackStyle = [
  //   {
  //     background: `linear-gradient(96.17deg, #2CD19E 53.16%, #47EF90 99.47%)`,
  //     height: `10px`,
  //   },
  // ];

  // const railStyle = {
  //   height: `10px`,
  // };

  // const handleStyle = [
  //   {
  //     border: `none`,
  //     opacity: `100`,
  //     backgroundColor: `#2CD19E`,
  //     top: `5px`,
  //     width: `20px`,
  //     height: `20px`,
  //   },
  // ];

  // const dotStyle = {
  //   top: `17px`,
  //   width: `1px`,
  //   height: `15px`,
  //   backgroundColor: `#dbe3eb`,
  //   border: `none`,
  //   borderRadius: `initial`,
  // };

  // const marks = {
  //   10: <SliderMarkBody4>50</SliderMarkBody4>,
  //   20: <SliderMarkBody4>250</SliderMarkBody4>,
  //   30: <SliderMarkBody4>500</SliderMarkBody4>,
  //   40: <SliderMarkBody4>1,000</SliderMarkBody4>,
  //   50: <SliderMarkBody4>5,000</SliderMarkBody4>,
  //   60: <SliderMarkBody4>10,000</SliderMarkBody4>,
  //   70: <SliderMarkBody4>50,000</SliderMarkBody4>,
  //   80: <SliderMarkBody4>150,000</SliderMarkBody4>,
  //   90: <SliderMarkBody4Red>+150,00</SliderMarkBody4Red>,
  // };

  // const [priceValue, setPriceValue] = useState(`14.99`);

  // const onSliderChangeHandler = (e) => {
  //   if (e === 10) {
  //     setPriceValue(`4.99`);
  //   } else if (e === 20) {
  //     setPriceValue(`9.99`);
  //   } else if (e === 30) {
  //     setPriceValue(`14.99`);
  //   } else if (e === 40) {
  //     setPriceValue(`19.99`);
  //   } else if (e === 50) {
  //     setPriceValue(`49.99`);
  //   } else if (e === 60) {
  //     setPriceValue(`99.99`);
  //   } else if (e === 70) {
  //     setPriceValue(`199.99`);
  //   } else if (e === 80) {
  //     setPriceValue(`359.99`);
  //   } else if (e === 90) {
  //     setPriceValue(`Enterprise`);
  //   }
  // };

  <>
    <FirstViewportWrapper>
      <FirstViewport imgWidth="50%" withRightPadding img={Vp1Image}>
        <H1>
          <span className="accent-text">{viewport1.title1}</span>
          {` `}
          {viewport1.title2}
          {` `}
          {viewport1.title3}
        </H1>
        <StyledStrong>
          {viewport1.description}
          {` `}
        </StyledStrong>
        <DescriptionWrapper>
          <TickedItems items={viewport1.descriptionItems} />
        </DescriptionWrapper>
        <ButtonDefault to={viewport1.button.url}>
          {viewport1.button.label}
        </ButtonDefault>
      </FirstViewport>
    </FirstViewportWrapper>
    <ViewportsWrapper>
      <SecondViewport>
        <Label>{viewport2.label}</Label>
        <CardWrapper>
          {viewport2.benefitCards.map((item) => (
            <CarouselCard
              key={item.label}
              flexBasis="25%"
              marginBottom="2rem"
              title={item.label}
              text={item.text}
              cardImgSrc={item.image}
            />
          ))}
        </CardWrapper>
      </SecondViewport>
      <ThirdViewport>
        <Label>{viewport3.label}</Label>
        <CardWrapper>
          {viewport3.useCaseCards.map((item) => (
            <UseCaseCardBig
              key={item.label}
              flexBasis="25%"
              marginBottom="2rem"
              title={item.title}
              label={item.label}
              text={item.text}
              cardImgSrc={item.image}
            />
          ))}
        </CardWrapper>
      </ThirdViewport>
      <FourthViewport>
        <Label>{viewport4.label}</Label>
        <StyledDescription>
          <span className="accent-text-green-bold">
            {viewport4.description1}
          </span>
          {` `}
          {viewport4.description2}
        </StyledDescription>
        <HowItWorksCardWrap>
          {viewport4.howItWorksCards.map((item) => (
            <HowItWorksCard
              key={item.title}
              title={item.title}
              text={item.text}
              image={item.image}
            />
          ))}
        </HowItWorksCardWrap>
      </FourthViewport>
      <FifthViewport>
        <StyledDescription>
          <span className="accent-text-green-bold">
            {viewport5.description1}
          </span>
          {` `}
          {viewport5.description2}
        </StyledDescription>
        <HowItWorksCardWrap>
          {viewport5.howItWorksCards.map((item) => (
            <HowItWorksCard
              key={item.title}
              title={item.title}
              text={item.text}
              image={item.image}
            />
          ))}
        </HowItWorksCardWrap>
      </FifthViewport>
      <SixthViewport resource={Vp6Image}>
        <StyledH2>{viewport6.title}</StyledH2>
        <StyledDescription>{viewport6.description}</StyledDescription>
        <PluginBody2>{viewport6.subtitle1}</PluginBody2>
        <PluginCardWrapper>
          {viewport6.eCommerceCards.map((item) => (
            <CbdPluginCard key={item.image} imageSrc={item.image} />
          ))}
        </PluginCardWrapper>
        <PluginBody2>{viewport6.subtitle2}</PluginBody2>

        <PluginCardWrapper>
          {viewport6.accountancyCards.map((item) => (
            <CbdPluginCard key={item.image} imageSrc={item.image} />
          ))}
        </PluginCardWrapper>
      </SixthViewport>
    </ViewportsWrapper>
    <SeventhViewport>
      {/* <StyledH2>{viewport7.title}</StyledH2>
        <StyledDescriptionVp7>{viewport7.description}</StyledDescriptionVp7>
        <SliderWrapper>
          <SliderMarkBody4>Up to</SliderMarkBody4>

          <StyledSlider
            trackStyle={trackStyle}
            railStyle={railStyle}
            handleStyle={handleStyle}
            dotStyle={dotStyle}
            defaultValue={30}
            min={10}
            max={90}
            step={null}
            marks={marks}
            onChange={(e) => {
              onSliderChangeHandler(e);
            }}
          />
        </SliderWrapper>
        <PriceWrapper>
          <PriceContainer>
            <PriceLeft>
              <Body1>Price</Body1>
            </PriceLeft>
            <PriceRight>
              <Price>{priceValue}</Price>
            </PriceRight>
          </PriceContainer>
        </PriceWrapper> */}
      <BottomMessageType2
        accentTextColor="#EF6355"
        bannerLabel1={viewport7.bannerLabel1}
        bannerLabel2={viewport7.bannerLabel2}
        bannerLabel3={viewport7.bannerLabel3}
        bannerDescription={viewport7.bannerDescription}
        button={viewport7.button}
        bannerBg={bannerBg}
      />
    </SeventhViewport>
  </>
);
export default RecurringPaymentsContainer;
